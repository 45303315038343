.abouts_img{
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}
section{
    padding: 50px 0px 0px;
}
.abouts_body > h4{
    margin-bottom: 10px;
    margin-top: 30px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 10px;
    margin-top: 30px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1.3;
    font-size: 15px;
}
.abouts_body > p{
    line-height: 2;
    letter-spacing: 0.03em;
    margin: 0;
    font-size: 13px;
    color: #777777;
}
@media (max-width:425px) {
    .abouts_main{
      padding: 0px 30px;
    }
}