.f_btns:hover {
  background-position: 100%;
  color: #000000 !important;
  background-color: #ffffff !important;
  /* border: 1px solid #7165e3;
  line-height: 20px; */
}

.f_btns {
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px !important;
  padding: 13px 29px;
  color: #ffffff !important;
  border: 1px solid #7165e3 !important;
  background-image: linear-gradient(30deg, #7165e3 50%, transparent 50%);
  background-size: 850px;
  background-repeat: no-repeat;
  background-position: 0;
}

.back{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px !important;
    border: 1px solid #7165e3 !important;
    padding: 5px 10px !important;
    color: #060606 !important;
}
.list_acc > .MuiPaper-root{
    box-shadow: none;
}