.f_field > label{
    font-size: 16px;
    font-weight: 600;
}
.f_field > input{
    border-color: #dddddd;
    font-size: 12px;
    padding: 15px 20px;

}
.f_btn{
    line-height: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 7px;
    transition: 0.3s ease-in-out;

    padding: 13px 29px;
    color: #ffffff;
    letter-spacing: 0.05em;
    border: 2px solid #7165E3;
    background-image: linear-gradient(30deg, #7165E3 50%, transparent 50%);
    background-size: 850px;
    background-repeat: no-repeat;
    background-position: 0;
    transition: background 300ms ease-in-out;
}

.f_btn:hover {
    background-position: 100%;
    color: #000000;
    background-color: #ffffff;
    border: 2px solid #7165E3;
}