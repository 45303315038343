.scrollbtn {
    color: white;
    border-radius: 100%;
    background-color: #7165e3;
    outline: none;
    border: none;
    z-index: 8;
    position: fixed;
    bottom: 130px;
    right: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
}