h4 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1;
}

.footer-logo {
    margin-bottom: 18px;
}

img {
    vertical-align: middle;
    border-style: none;
}
.footer-title h5 {
    font-size: 1.25rem;
    font-weight: 400;

    font-family: Lato, sans-serif;
    color: #222222;
    line-height: 24px;
    letter-spacing: 0.05em;
}

p {
    margin-top: 0;
    font-size: 14px;
    color: #777777;
    line-height: 32px;
    letter-spacing: 0.03em;
    margin-bottom: 0;
}

.footer-social {
    margin-top: 40px;
    display: flex;
    align-items: center;
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

.footer-social p {
    display: inline-block !important;
    padding-right: 45px;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: 0.5s ease;
}

a:hover {
    text-decoration: none;
    transition: 0.5s ease;
}

.icon {
    font-size: 20px;
    color: #000000;
    transition: 0.5s ease;
}

.icon:hover {

    color: #7165e3 !important;
    transition: 0.5s ease;
}

.sub-title h4 {
    color: #222222;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.03em;
    line-height: 1;

}

.sub-title p {
    position: relative;
    display: inherit;
    padding-top: 13px;
    text-transform: capitalize;
    font-size: 14px;
    color: #777;
}

.sub-title p a {
    position: relative;
    color: #777;
}

.sub-title p:hover a:before {
    width: 100%;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.sub-title p a:before {
    position: absolute;
    top: 20px;
    content: "";
    height: 2px;
    width: 0;
    background-color: #7165E3;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.sub-title .contact-list p {
    position: relative;
    padding-left: 25px;
    line-height: 28px;
}

.sub-title .contact-list i {
    position: absolute;
    left: 0;
    top: 21px;
}

/* sub footer */

.sub-footer {
    background-color: #fff;
}

.sub-footer p {
    color: #777;
    padding: 20px 0;
    line-height: 32px;
    letter-spacing: 0.03em;
    font-size: 14px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;

}

.sub-footer .payment-card-bottom {
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.sub-footer .payment-card-bottom li {
    padding-left: 7px;
    padding-right: 7px;
    display: inline-block;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.light-layout {
    background-color: #f9f9f9
}

section {
    padding: 70px 0px;
}

.footer-mobile-title {
    display: none;
}


@media only screen and (max-width:768px) {
        section{
          padding: 10px;
        }
    .payment-card-bottom {
        height: 100%;
        display: flex !important;
         justify-content: center !important;
         margin-bottom: 15px !important;
       
    }
    .categories{
        display: flex;
        align-items: center;       
        flex-direction: column;
       margin-top: 20%;
        
    }
    
    .contact{
        margin-top: 1rem;
    }
    .sub-title p {
        padding-top: 2px;
    }
    .copywrite{
        display: flex !important;
        justify-content: center !important;
    }
   .footer-social{
    display: flex;
    justify-content: center;
    margin: 5px 0px 35px;
   }
   .row{
       margin-top: 0px !important;       
   }
   .fotter_row{
       display: grid;
       grid-template-columns: repeat(2,2fr);
      
   }
   .contact{
       display: flex;
       justify-content: center;
       flex-direction: column;
       align-items: center;
   }
}

@media only screen and (max-width:425px) {
        .row{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
        .footer-social > p{
           padding-right: 20px;
        }
        .categories{
            margin-bottom: 2rem;
            padding-left: 0px;
            margin-top: 0px;
            
        }
        .copywrite{
            text-align: center;
        }
}
@media only screen and (max-width:2560px) {
           
        .main{
            flex: 1;
        }
        .categories{
           flex: 1; 
        }
        .contact{
            flex: 1;
        }
        .choose{
            flex: 1;
        }
        
}