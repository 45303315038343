.list_country {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  width: 60vw;
  height: 220px;
}
.f_inputs {
  padding: 11px 8px !important;
  display: inline-block;
  border-radius: 8px;
}
.s_option {
  width: 200px;
  border-radius: 8px;
  padding: 10px 8px !important;
}
.resend {
  color: #7165e3;
  font-weight: 5px;
}

@media (max-width: 768px) and (min-width: 426px) {
  .list_country {
    width: 80vw;
  }
}
@media (max-width: 425px) {
  .list_country {
    width: 80vw;
    height: 290px;
  }
  .country_field {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .select_fields {
    margin-bottom: 15px;
    margin-top: 25px;
  }
  .otp_main > p {
      font-size: 12px !important;
  }
  .otp > p {
      font-size: 11px !important;
  }
  .otp > h6 {
      font-size: 15px  !important;
  }
  .sucess_otp{
    padding: 0 !important;
  }
  .sucess_otp > p{
    font-size: 13px;
    width: 95vw;
    padding: 0px 5px;
    text-align: center;
  }
  .sucess_otp > img{
    max-width: 100%;
    height: 100px;
  }
}
@media (min-width: 769px) {
  .list_country {
    width: 60vw;
  }
}
