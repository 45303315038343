@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

.right_cart {
  display: flex;
}
.delte {
  margin-left: 15px;
  margin-top: 5px;
}
.nav-item > a {
  margin-right: 35px;
  color: #222222;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  font-family: "Varela Round", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 0;
  letter-spacing: 0.07em;
  position: relative;
}
.nav-link:hover {
  color: #7165e3 !important;
}
.cart_qty_cls {
  position: relative;
  background: #7165e3;
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  bottom: 20px;
  right: 20px;
  padding: 3px 3px;
}

.nav-item .dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  margin-top: 25px;
  z-index: 1;
  
}
.nav-item:hover .dropdown-menu {
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  top: 40% !important;
  transform: rotateX(0deg); 
  color: #7165e3 !important;
}
.nav-item:hover .cart_ul {
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  top: 40%;
  right: 6%;
  transform: rotateX(0deg); 
}
.dropdown-item:active {
    color: inherit;
    text-decoration: none;
    background-color: inherit;
}
 .dropdown-item:hover {
    color: #1e2125;
    background-color: inherit;
}
.dropdown-menu > li {
    position: relative;
    color: #0087ca;
  }
.dropdown-menu > li:after {
    content: '';
    position: absolute;
    width:60px;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 18px;
    background-color: #7165e3;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .dropdown-menu > li:hover:after {
    transform: scaleY(1);
    transform-origin: bottom left;
  }
  .cart_list{
    width: 220px;
    height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
@media (max-width: 989px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    padding-left: 15px;
    height: 100%;
  }
  .navbar-collapse.collapsing {
    height: 100%;
    right: -75%;
    transition: height 0s ease;
  }
  .nav-item > a {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold !important;
    color: #222222;
    text-transform: uppercase;
    letter-spacing: 0.07em;
  }
  .navbar-collapse.show {
    height: 100%;
    width: 300px;
    right: 0;
    transition: right 400ms ease-in-out;
    background-color: white;
    z-index: 3;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: right 400ms ease-in;
  }
  .side_btn {
    margin-top: 10px;
    position: absolute;
    right: 5px;
    border: none;
  }
  .navbar-nav {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    margin-top: 15px;
  }
  .dropdown-menu > li {
    display: flex;
    flex-direction: column;
  }
  .hamburger {
    outline: none;
    border: none;
    padding: 3px;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  .navbar-nav .dropdown-menu {
     position: absolute; 
}
}
@media (max-width: 1024px) {
  .nav-item > a {
    padding-right: 1px !important;
    padding-left: 1px !important;
    letter-spacing: 0.02em !important;
  }
  
}
@media (max-width: 768px) {
  .container {
    max-width: 720px;
  }
 
.left {
    padding: 0px;
}
}
@media (max-width: 425px) {
    .navbar-collapse.show {
        height: 100%;
        width: 200px;
        right: 0;
        transition: right 400ms ease-in-out;
        background-color: white;
        z-index: 1;
      }
      .navbar {
        padding-top: 0rem;
        padding-bottom: 6rem;
        height: 0px;
    }
    .left {
        padding: 0px;
    }
   
}
