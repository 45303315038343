.bank_main{
    position: relative;
}
.bank_head h1::after {
    position: absolute;
    height: 2px;
    width: 70px;
    background-color: #7165E3;
    background-color: var(--theme-deafult);
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 40px;
}
.label_heading>h5{
    color: #9EA6BE;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
}
