.jumb{
    text-transform: uppercase;  
    padding: 25px;
}
.jumbotron{
    background-color: #f8f8f8;
}
.left_jumb{
    padding: 0.75rem 0rem;
}
.left_jumb > h2{
    font-size: 16px;
    color: #222222;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0px;
    letter-spacing: 0.02em;
}
.breadcrumb {
    padding: 0.75rem 1rem;
    font-size: 14px;
    color: #555555;   
    margin: 0;
    padding-right: 0;
}
@media (max-width:425px) {
    .jumb{
        display: flex;
        flex-direction: column;
    }
}