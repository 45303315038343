.P_detail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
.carousel .slide img {
  width: 90% !important;
  vertical-align: top;
  border: 0;
}
.carousel-slider {
  height: 0%;
}
.carousel {
  height: 0%;
  text-align: center;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 1px solid #7165e3 !important;
}
.carousel .thumb {
  width: 130px !important;
}
.input-number{
    width: 60px;
    border: 1px solid black;
    text-align: center;
    background: #e9ecef;
    border: 1px solid #ced4da;
    opacity: 1;
    height: 40px;
    color: #777777;
}
.quantity_minus,.quantity_plus{
    height: 40px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background: #ffffff !important;
    border: 1px solid #ced4da;
    color: #777777;
}
.quantity_plus{
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.Detail_img{
  max-width: 100%;
}
.Detail_size{
  cursor: pointer;
  color: blue;
}
.pd_button{
  background-color: transparent;
  border-radius: 15px;
  text-transform: capitalize;
  border: 1px solid grey;
  font-size: 15px;
  padding: 2px 10px;
  margin-right: 10px;
}
.pd_span{
  font-size: small;
}
.li_span{
  color: #777777;
  font-size: small;
}
.wishlist{
  cursor: pointer;
}

.wishlist:hover .p_icon,.p_icon:hover{
  cursor: pointer;
    color: #7165e3;
}
.freechash{
  display: flex;
  background: #DFF7FC;
  justify-content: center;
  align-items: center;
}
.return{
  background: #FBE9F1;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .librery{
  height: 100px;
} */
.librery > div > img{
  height: 50px;
  width: 55px;
  padding: 3px;
}
.librery > div > h4{
font-size: 13px;
padding: 10px 10px 10px 3px;
}
.lowprice{
  background: #FDF4E6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list_first{
  font-size: 14px;
  width: 40%;
}
.list_number{
  font-size: 14px;
}
.review_heading{
  position: relative;
}
.review_heading >h6::after {
  position: absolute;
  height: 2px;
  width: 60px;
  background-color: #7165E3;
  content: "";
  left: 5px;
  top: 25px;
}

.reviw{
    background: #4AA54A;
    padding: 3px;
    border-radius: 5px;

}
.media_body {
  flex: 1;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
}
.comment-section li h6 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  color: #777777;
  margin-left: 20px;
}
.comment-section li p {
  line-height: 1.8;
  margin-bottom: -4px;
  letter-spacing: 0.09em;
}
.medias img {
    height: 65px;
    width: 65px;
    margin-right: 20px;
    border-radius: 100%;
}
.comnt-sec li a {
  color: #777777;
  font-size: 16px;
}
@media (max-width: 425px) {
  .P_detail {
    grid-template-columns: 1fr;
  }
  .carousel .thumb {
    width: 90px !important;
  }
  .carousel .slide img {
    width: 80% !important;
  }
  .detail_first{
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .first_head{
     text-align: center;
   }
   .input_group{
     justify-content: center;
   }
   .input_btn{
     justify-content: center;
   }
   .product_size{
     display: flex;
     justify-content: center;
   }
   .p_list{
     display: flex;
     justify-content: center;
   }
   .sublist{
     justify-content: center;
   }
   .librery{
     flex-direction: column;
   }
   .freechash{
     flex-direction: column;
   }
   .return{
    flex-direction: column;
  }
  .lowprice{
    flex-direction: column;
  }
  .medias{
    flex-direction: column;
  }
  .medias > img{
    margin: auto;
  }
  .media_body>h6{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .media_body>div{
    text-align: center;
  }
  .media_body>ul{
    text-align: center;
  }

}
