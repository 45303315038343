.accordion-button:not(.collapsed) {
  color: unset;
  background-color: unset;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button::after {
  color: black;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../../../assets/down-arrow.png");
}
.accordion-button:focus {
  border-color: unset;
  outline: unset;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-body {
  max-height: 210px;
  overflow: scroll;
  overflow-y: visible;
  overflow-x: hidden;
}
.scrollbar-frozen-dreams::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-frozen-dreams::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.scrollbar-frozen-dreams::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: linear-gradient(
    to top,
    #7165E3 0%,
    #7165E3 1%,
    #e6dee9 100%
  );
}
.list_head{
    color: #7165E3 ;
    cursor: pointer;
}
.subSubHead{
  cursor: pointer;
}
.sublist_head{
  cursor: pointer;
}
@media (max-width:768px) and (min-width:426px) {
  .rest_filter >button {
    font-size: 15px !important;
  }
  .accordion-body {
    padding: 10px 8px;
  }
  .accordion-button{
    font-size: 15px !important;
    padding: 10px 12px;
  }
  .list_head{
    font-size: 20px !important;
  }
  .sublist_head{
    font-size: 15px !important;
  }
}
@media (max-width:425px) and (min-width:320px) {
 
  .rest_filter >button {
    font-size: 15px !important;
  }
  .accordion-button{
    font-size: 15px !important;
    padding: 10px 12px;
  }
  .list_head{
    font-size: 20px !important;
  }
  .sublist_head{
    font-size: 18px !important;
  }
}