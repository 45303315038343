.forms_field > label{
    color: #333333;
    font-size: 15px;
    
}
.forms_field > input {
    border-color: #dddddd;
    font-size: 14px;
    padding: 15px 20px;
}
.forms_field > select {
    border-color: #dddddd;
    font-size: 14px;
    padding: 15px 20px;
}
.custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background: linear-gradient(270deg, #3fa1a9, #79f1a4);
    margin-bottom: 25px;
}
.img-wrap {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}
.img-wrap img{
    height: 100%;
    width: 100%;
   
}
.img-upload:before {
    content: "\f093";
    font-size: 90px;
    position: absolute;
    padding-top: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #63d3a6;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fff;
}
.fas >input{
    display: none;
}
